import React from 'react'
import './footer.css';


function Footer() {

  const [result, setResult] = React.useState("Submit");

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "79f4ee02-3a81-4afe-931c-4a5d1b09a3da");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };

  return (
    <div className='fbody'>
      <div class="newsletter">
   <h1 style={{color:'#BB9E03'}}>
    Contact Us
   </h1>
   <form onSubmit={onSubmit}>
   <input placeholder="Your Name" type="Text" name="name"/>
   <br></br>
   <input placeholder="Your Email" type="email" name="email"/>
   <br></br>

   <textarea placeholder="Message..." type="Text" name="Message"/>
   <br></br>

   <button type="submit" style={{margin:10,background:'#BB9E03'}}>{result}
    
   </button>
   </form>
  </div>
  <div class="footer">
   <div class="container">
    <div class="column">
     <img alt="Farmor logo" src="icons/logo.png" width="100"/>
     <p>
     At The Smart Grains, we are at the forefront of B2B grain trading, connecting producers and buyers through a seamless, reliable, and efficient platform. 
     </p>
     <div class="social-icons">
      <a href="#">
       <i class="fab fa-facebook-f">
       </i>
      </a>
      <a href="#">
       <i class="fab fa-twitter">
       </i>
      </a>
      <a href="#">
       <i class="fab fa-instagram">
       </i>
      </a>
      <a href="#">
       <i class="fab fa-pinterest">
       </i>
      </a>
     </div>
    </div>
    <div class="column">
    <h3>
    Office Address
     </h3>
     <ul>
      <li>
       <a href="#">
       253, Anil Nagar, Barfani Dham, infront of Mathur vaisya dharashala Indore -452001 (M.P.)
       </a>
      </li>
      </ul>

      <h3>
      Registered address
     </h3>
     <ul>
      <li>
       <a href="#">
       B-4, 505, Shalimar shwayam Bhangadh Road Indore – 452010 (M.P.)
       </a>
      </li>
      </ul>

     

     {/* <h3>
      Links
     </h3>
     <ul>
      <li>
       <a href="#">
        About Us
       </a>
      </li>
      <li>
       <a href="#">
        Why Choose Us
       </a>
      </li>
      <li>
       <a href="#">
        Meet Our Team
       </a>
      </li>
      <li>
       <a href="#">
        Contact Us
       </a>
      </li>
      <li>
       <a href="#">
        FAQs
       </a>
      </li>
     </ul> */}
    </div>
    <div class="column">
     <h3>
      Policy &amp; Terms
     </h3>
     <ul>
    
      <li>
       <a href="Terms.html">
        Terms &amp; Condition
       </a>
      </li>
      <li>
       <a href="Policy.html">
        Privacy Policy
       </a>
      </li>
     </ul>
    </div>
    <div class="column">
    <h3>
      Contact Number
     </h3>
     <ul>
      <li>
       <a href="#">
       +91-930-398-9801, +91-881-555-9387
       </a>
      </li>
      </ul>

      <h3>
      Email ID
     </h3>
     <ul>
      <li>
       <a href="#">
       info@thesmartgrains.in
       </a>
      </li>
      </ul>
    </div>
    {/* <div class="column gallery"> */}


     {/* <h3>
      Gallery
     </h3>
     <img alt="Image of wheat field" height="70" src="https://www.caritasindia.org/wp-content/uploads/2019/02/1.jpg" width="70"/>
     <img alt="Image of fresh berries" height="70" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRO4hyEdPryusijWqm-PrAQDUqrZh3_CgG8A&s" width="70"/>
     <img alt="Image of fresh lettuce" height="70" src="https://i0.wp.com/blog.bijak.in/wp-content/uploads/2022/11/6.jpg?resize=940%2C535&ssl=1" width="70"/>
     <img alt="Image of corn field" height="70" src="https://admin.spmesmandal.org/uploads/project/656ffbd9708a8.jpg" width="70"/>
     <img alt="Image of orchard" height="70" src="https://agriculturepost.com/wp-content/uploads/2022/03/Every-block-in-India-to-have-one-farmer-producer-organisation-MoS-Agriculture-650x400.jpg" width="70"/>
     <img alt="Image of cabbage" height="70" src="https://imengine.public.prod.pdh.navigacloud.com/?uuid=235AEAF6-15C5-4141-9BB7-1622DC070F23&type=preview&function=cover&height=609&width=800" width="70"/>
     <img alt="Image of fresh vegetables" height="70" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1Vi_8blIShd5FkuOKDxtXjitUfTyrY67gtw&s" width="70"/>
     <img alt="Image of wheat" height="70" src="https://tci.cornell.edu/wp-content/uploads/2021/07/IMG_6762.jpg" width="70"/> */}
    {/* </div> */}
   </div>
   <div style={{background:"black",padding:20}} class="copyright">
    Copyright 2024 @The Smart Grains Limited. All Rights Reserved.
   </div>
   {/* <div class="copyright">
    Designed By -
    <a className='Company' style={{textDecoration:"none",color:"#9b9191d9"}} href='https://duniyape.in/'> Duniyape.in</a>
   </div> */}
  </div>
 
    </div>
  )
}

export default Footer
